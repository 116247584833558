import React from 'react';
import PageDataTableReport from '../../components/PageDataTableReport';

const VerteilungDerGesellschaftenLV = () => {
    return (
        <PageDataTableReport
            title="Verteilung der Gesellschaften LV"
            reportCode="distribution-of-the-insurance-companies-in-life-insurances"
        />
    );
};

export default VerteilungDerGesellschaftenLV;

import React from 'react';
import PageDataTableReport from '../../components/PageDataTableReport';

const VerteilungDerGesellschaftenKV = () => {
    return (
        <PageDataTableReport
            title="Verteilung der Gesellschaften KV"
            reportCode="distribution-of-the-insurance-companies-in-health-insurances"
        />
    );
};

export default VerteilungDerGesellschaftenKV;

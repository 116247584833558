import ReportData from '../models/ReportData';
import LineChartData, { Line2D, Point2D } from '../models/LineChartData';
import StackedBarData, { BarDataBlock } from '../models/StackedBarData';
import TableChartData from '../models/TableChartData';

export const toLineChartData = (data: ReportData): LineChartData => {
    if (!data.length) {
        return [];
    }

    if (!data[0]?.report?.broker?.value) {
        // data is multiline
        return toMultilineChartData(data);
    }

    const brokerLine: Line2D = { name: 'Broker', data: [] };
    const childrenLine: Line2D = { name: 'Network', data: [] };

    data.forEach((month) => {
        brokerLine.data.push({ x: month.period, y: month.report.broker.value });
        childrenLine.data.push({ x: month.period, y: month.report.network.value });
    });

    return [brokerLine, childrenLine];
};

export const toMultilineChartData = (data: ReportData): LineChartData => {
    if (!data?.length) {
        return [];
    }

    const mapData = (key: 'broker' | 'network', name: string): Line2D[] => {
        const lines: Line2D[] = [];
        const categories: string[] = Object.keys(data[0].report[key]);
        categories.forEach((category) => {
            const line: Point2D[] = data.map((month) => {
                return { x: month.period, y: month.report[key][category] };
            });

            lines.push({ name: `${name}-${category}`, data: line });
        });

        return lines;
    };

    return [...mapData('broker', 'Broker'), ...mapData('network', 'Network')];
};

const limitSeries = (series: BarDataBlock[]) => {
    const limit = 10;
    if (series.length > limit) {
        series = series.sort((a, b) => (a.value > b.value ? -1 : 1));
        let rest = 0;
        for (let i = limit; i < series.length; i++) {
            rest += series[i].value;
        }

        series = [...series.slice(0, limit), { name: 'Weitere Sparten', value: rest }];
    }

    return series.reverse();
};

export const toStackedBarData = (data: ReportData): StackedBarData[] => {
    if (!data?.length) {
        return [];
    }

    if (data[0]?.report?.broker[0]?.section) {
        // data is fixed
        return toSectionStackedBarData(data);
    }

    const mapData = (key: 'broker' | 'network'): StackedBarData => {
        const categoriesNames: string[] = Object.keys(data[0].report[key]);

        return data.map((month) => {
            let series: BarDataBlock[] = categoriesNames.map((category) => ({
                name: category,
                value: month.report[key][category]
            }));

            series = limitSeries(series);

            return { category: month.period, series: series };
        });
    };

    return [mapData('broker'), mapData('network')];
};

export const toSectionStackedBarData = (data: ReportData): StackedBarData[] => {
    if (!data?.length) {
        return [];
    }

    const mapData = (key: 'broker' | 'network'): StackedBarData => {
        return data.map((month) => {
            const series: BarDataBlock[] = month.report[key].map((category: any) => ({
                name: category.section,
                value: category.value
            }));

            return { category: month.period, series: limitSeries(series) };
        });
    };

    return [mapData('broker'), mapData('network')];
};

export const toNumberChartData = (data: ReportData): number[] => {
    if (!data?.length) {
        return [0, 0];
    }

    const report = data[data.length - 1].report;

    return [report.broker.value, report.network.value];
};

export const toTableChartData = (data: ReportData): TableChartData[] => {
    if (data.length === 0) {
        return [];
    }

    const report = data[data.length - 1].report;
    const mapData = (data: any[] | Record<string, any>): TableChartData => {
        return (
            data?.map?.((row: any) => ({
                name: row.companyName as string,
                value: row.value
            })) || []
        );
    };

    return [mapData(report.broker), mapData(report.network)];
};

// all methods return an array where
// the first item is the broker data, and
// the second item is the network data
const DataTransformer = {
    toLineChartData,
    toMultilineChartData,
    toStackedBarData,
    toSectionStackedBarData,
    toNumberChartData,
    toTableChartData
};

export default DataTransformer;

import React, { useCallback, useState } from 'react';

import StackedBarData from '../../models/StackedBarData';
import StackedBarChart from '../chart/StackedBarChart/StackedBarChart';
import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';
import BaseReport from './BaseReport';
import Report from './Report';

const BarReport: React.FC<Report> = ({ reportCode, brokerId, brokerReport, title, valueSuffix, ...props }) => {
    const [data, setData] = useState<StackedBarData[]>([]);

    const getData = useCallback(() => {
        return ReportsService.get(reportCode, brokerId).then(DataTransformer.toStackedBarData).then(setData);
    }, [brokerId, reportCode]);

    return (
        <BaseReport title={title} getCallback={getData} type="chart">
            <StackedBarChart
                data={brokerReport ? data[0] : data[1]}
                valueSuffix={props.percentage ? '%' : valueSuffix}
                {...props}
            />
        </BaseReport>
    );
};

export default BarReport;

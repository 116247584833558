import React from 'react';

import NumberFormatter from '../../../utilities/NumberFormatter';
import TableChartData from '../../../models/TableChartData';
import EmptyData from '../../EmptyData/EmptyData';

type TableChartProps = {
    data: TableChartData;
};

const DataTable: React.FC<TableChartProps> = ({ data }) => {
    if (!data?.length) {
        return <EmptyData />;
    }

    return (
        <table className="dr-data-table">
            <tbody>
                {data?.map((stat: any, index: number) => {
                    return (
                        <tr key={index} className="drp-row">
                            <td className="drp-order-number">{index + 1}</td>
                            <td className="drp-name">{stat.name}</td>
                            <td className="drp-value">
                                <span>{NumberFormatter.format(stat.value, 2)}</span>
                                <span className="drm--currency">€</span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DataTable;

import React from 'react';
import PageBarReport from '../../components/PageBarReport';

const VertragsdichtePrivatInBetreuung = () => {
    return (
        <PageBarReport
            title="Vertragsdichte privat in Betreuung"
            reportCode="contract-volume-of-private-care"
            roundDigits
            averageSum
        />
    );
};

export default VertragsdichtePrivatInBetreuung;

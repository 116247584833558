import React, { ReactNode, useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import ChartDefaults from '../ChartDefaults';
import NumberFormatter from '../../../utilities/NumberFormatter';
import { Colors } from '../../../constants';
import StackedBarData from '../../../models/StackedBarData';
import EmptyData from '../../EmptyData/EmptyData';

const translations: Record<string, string> = {
    CtrG18: 'Altersgruppe 18-29',
    CtrG30: 'Altersgruppe 30-39',
    CtrG40: 'Altersgruppe 40-49',
    CtrG50: 'Altersgruppe 50-59',
    CtrG60: 'Altersgruppe 60+',
    CstG18: 'Altersgruppe 18-29',
    CstG30: 'Altersgruppe 30-39',
    CstG40: 'Altersgruppe 40-49',
    CstG50: 'Altersgruppe 50-59',
    CstG60: 'Altersgruppe 60+',
    CNN: 'N - Beitragsfrei',
    CNR: 'R - Beitragsfrei (DV)',
    CNV: 'V - Übertragung Komplettbestand',
    CNU: 'U - Bestandsübertragung',
    CNK: 'K - Korrespondenzmaklerschaft',
    CNH: 'H - Aktiver Vertrag Honorarberatung',
    CNE: 'E - Beantragt (DV)',
    CNB: 'B - Beantragt ohne Policeneingang',
    CNA: 'A - Aktiver Vertrag',
    CNF: 'F - Aktiver Vertrag (DV)',
    BC: 'Gewerbekunden',
    PC: 'Privatkunden',
    female: 'weiblich',
    male: 'männlich',
    LVSS: 'Leben',
    KrSS: 'Kranken',
    KoSS: 'Komposit'
};

const transformTooltip = (
    data: any[],
    valueSuffix: string,
    roundDigits: boolean,
    averageSum: boolean
): { items: ReactNode; total: string }[] => {
    return data?.map((datum: Record<string, any>) => {
        const keys = Object.keys(datum)
            .reverse()
            .filter((k) => k !== 'name');
        let total = keys.reduce((total: number, key: string) => total + datum[key], 0);
        if (keys.length && averageSum) {
            total = total / keys.length;
        }

        return {
            items: keys.map((key, i) => (
                <li key={key}>
                    <span className="drm--color" style={{ backgroundColor: Colors[keys.length - 1 - i] }} />
                    <span className="drm--text">
                        {translations[key] || key}: {NumberFormatter.format(datum[key], roundDigits ? 2 : undefined)}
                        {valueSuffix}
                    </span>
                </li>
            )),
            total: NumberFormatter.format(total, roundDigits ? 2 : undefined)
        };
    });
};

const extractKeys = (data: StackedBarData) => {
    const keys: string[] = [];
    data?.forEach((category) => {
        category.series.forEach((block) => {
            if (!keys.includes(block.name)) {
                keys.push(block.name);
            }
        });
    });

    return keys;
};

const transformData = (data: StackedBarData, percentage: boolean) => {
    return data?.map((category) => {
        const data: any = { name: category.category };
        category.series.forEach((block) => {
            data[block.name] = percentage ? block.value * 100 : block.value;
        });

        return data;
    });
};

type StackedBarProps = {
    data: StackedBarData;
    valueSuffix?: string;
    roundDigits?: boolean;
    percentage?: boolean;
    averageSum?: boolean;
};

const StackedBarChart: React.FC<StackedBarProps> = ({
    data,
    valueSuffix = '',
    roundDigits = false,
    percentage = false,
    averageSum = false
}) => {
    const [max, setMax] = useState<number>(100);

    useEffect(() => {
        const maxValue = data?.reduce((max, category) => {
            return Math.max(
                max,
                category.series.reduce((total, block) => total + Number(block.value), 0)
            );
        }, 0);

        setMax(maxValue * 1.1 * (percentage ? 100 : 1));
    }, [data, percentage]);

    const StackedBarChartTooltip: React.FC<Record<string, any>> = ({ index, tooltipData }) => {
        setTimeout(() => {
            document.querySelectorAll('.dr-chart-tooltip').forEach((tooltipEl) => {
                tooltipEl.parentElement?.classList.add('dr-chart-tooltip-wrapper');
            });
        }, 10);

        return (
            <div className={'dr-chart-tooltip ' + (index >= tooltipData.length / 2 - 1 ? 'drm--left' : 'drm--right')}>
                <ul>{tooltipData[index].items}</ul>
                <footer>
                    {averageSum ? 'Durchschnitt' : 'Gesamt'}: {tooltipData[index].total}
                    {valueSuffix}
                </footer>
            </div>
        );
    };

    if (!data?.length) {
        return <EmptyData />;
    }

    const tooltipData = transformTooltip(transformData(data, percentage), valueSuffix, roundDigits, averageSum);

    // noinspection RequiredAttributes
    return (
        <ResponsiveBar
            keys={extractKeys(data)}
            indexBy="name"
            data={transformData(data, percentage)}
            padding={0.3}
            enableGridX={true}
            enableLabel={false}
            valueScale={{ type: 'linear', min: 0, max: max }}
            indexScale={{ type: 'band', round: true }}
            role="application"
            tooltip={(props) => <StackedBarChartTooltip tooltipData={tooltipData} {...props} />}
            {...ChartDefaults(max, percentage)}
        />
    );
};

export default StackedBarChart;

import React, { useEffect, useState } from 'react';

import Loader from '../loader/Loader';
import ChartSection from '../chart/ChartSection';
import AuthContext from '../../providers/AuthContext';

type Props = {
    type: 'chart' | 'table' | 'number';
    title: string;
    getCallback: () => Promise<void>;
};

const BaseReport: React.FC<Props> = ({ type, title, getCallback, children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (!getCallback) {
            return;
        }

        setIsLoading(true);
        getCallback()
            .catch((error: any) => {
                if (error?.status === 401) {
                    AuthContext.logout();
                    window.location.reload();
                } else {
                    setError('Error fetching the report data: ' + (error?.message || 'unknown error.'));
                }
            })
            .finally(() => setIsLoading(false));
    }, [getCallback]);

    return (
        <ChartSection header={title} type={type} bodyClassName={error ? 'drs--error' : ''}>
            {isLoading ? <Loader /> : error ? <span className="drp-error">{error}</span> : children}
        </ChartSection>
    );
};

export default BaseReport;

import BaseService from './BaseService';
import AuthContext from '../providers/AuthContext';

const BrokerService = {
    getChildren: (): Promise<string[]> => {
        const service = BaseService();

        return service
            .get(`/reports/children/${AuthContext.getUser().id}`)
            .then((response: { id: string }[]) => response.map((o) => o.id))
            .catch((error: any) => Promise.reject(error.response?.data || error));
    }
};

export default BrokerService;

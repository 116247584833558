import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method } from 'axios';
import AuthContext from '../providers/AuthContext';
import OAuthService from './OAuthService';

export interface IBaseService {
    get: (url: string, headers?: { [key: string]: string }) => Promise<any>;
    post: (url: string, data?: any, headers?: { [key: string]: string }) => Promise<any>;
    getLastRequestConfig: () => AxiosRequestConfig | null;
    getLastResponse: () => AxiosResponse | null;
}

let tokenPromise: Promise<void> | null = null;

const BaseService = (apiUrl?: string, useAuth = true): IBaseService => {
    const baseUrl = apiUrl || process.env.REACT_APP_API_URL;

    const baseHeaders: AxiosRequestHeaders = {
        'Content-type': 'application/json'
    };

    let lastRequestConfig: AxiosRequestConfig = {};
    let lastResponse: AxiosResponse | null = null;

    const errorHandler = (response: AxiosResponse) => {
        lastResponse = response;

        return Promise.reject(response);
    };

    const call = (method: Method, url: string, headers?: { [p: string]: string }, data?: any): Promise<any> => {
        const doCall = () => {
            url = baseUrl?.replace(/\/+$/, '') + '/' + url.replace(/^\/+/, '');

            lastRequestConfig = {
                method,
                data,
                url,
                headers: { ...baseHeaders, ...headers },
                validateStatus: (status) => (status >= 200 && status < 400) || status === 404
            };

            return axios(lastRequestConfig);
        };

        if (useAuth && AuthContext.isAuthenticated()) {
            if (!tokenPromise) {
                tokenPromise = OAuthService.getAccessToken();
            }

            return tokenPromise
                .then((token) => {
                    baseHeaders.Authorization = 'Bearer ' + token;
                    return doCall();
                })
                .finally(() => {
                    tokenPromise = null;
                });
        }

        return doCall();
    };

    const get = (url: string, headers?: { [key: string]: string }): Promise<any> => {
        return call('GET', url, headers)
            .then((response) => {
                if (response.status === 404) {
                    return {};
                }

                return response.data;
            })
            .catch(errorHandler);
    };

    const post = (url: string, data?: any, headers?: { [key: string]: string }): Promise<any> => {
        return call('POST', url, headers, data)
            .then((response) => {
                if (response.status === 404) {
                    return {};
                }

                return response.data;
            })
            .catch(errorHandler);
    };

    const getLastRequestConfig = () => lastRequestConfig;

    const getLastResponse = () => lastResponse;

    return {
        get,
        post,
        getLastRequestConfig,
        getLastResponse
    };
};

export default BaseService;

import React from 'react';
import PageLineReport from '../../components/PageLineReport';

const StornoquoteImHaftungszeitraum = () => {
    return (
        <PageLineReport
            title="Stornoquote im Haftungszeitraum"
            reportCode="cancellation-quote-in-the-period-of-liability"
            roundDigits
        />
    );
};

export default StornoquoteImHaftungszeitraum;

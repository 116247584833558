import NumberFormatter from '../../utilities/NumberFormatter';
import { Colors } from '../../constants';

const ChartDefaults = (maxValue?: number, percentage?: boolean) => {
    const marginLeft = maxValue ? String(Math.round(maxValue * 10000) / 10000).replace('.', '').length * 7 : 10;

    return {
        isInteractive: true,
        colors: [...Colors],
        margin: { top: 20, right: 10, bottom: 30, left: marginLeft },
        axisBottom: { tickSize: 0 },
        axisLeft: {
            tickSize: 0,
            tickPadding: 10,
            tickValues: 6,
            format: (value: any) => NumberFormatter.format(value, undefined) + (percentage ? '%' : '')
        },
        axisTop: { tickSize: 0, format: () => '' },
        axisRight: { tickSize: 0, format: () => '' },
        theme: {
            axis: {
                domain: {
                    line: {
                        stroke: '#dddddd',
                        strokeWidth: 1
                    }
                }
            }
        }
    };
};

export default ChartDefaults;

import React from 'react';

type Props = {
    header: string;
    type?: 'chart' | 'table' | 'number';
    bodyClassName?: string;
};

const ChartSection: React.FC<Props> = ({ header, type = 'chart', bodyClassName = '', children }) => {
    return (
        <section className={'drt--' + type}>
            <header>{header}</header>
            <div className={`drp-body ${bodyClassName}`}>{children}</div>
        </section>
    );
};

export default ChartSection;

import React from 'react';

export type BaseContextType = {
    brokerReport: boolean;
    brokerId: string;
    setBrokerReport: (value: boolean) => void;
    setBrokerId: (value: string) => void;
};

export const BasePageContext = React.createContext<BaseContextType>({
    brokerReport: true,
    brokerId: '',
    setBrokerReport: () => {
        throw Error('setBrokerReport not implemented in Context Provider');
    },
    setBrokerId: () => {
        throw Error('setBrokerId not implemented in Context Provider');
    }
});

export const usePageContext = (): BaseContextType => {
    return React.useContext(BasePageContext);
};

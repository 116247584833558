import React from 'react';

import { PageReport } from '../../components/reports/Report';
import { usePageContext } from '../../components/pages/BasePage/BasePageContext';
import BarReport from '../../components/reports/BarReport';

const PageBarReport: React.FC<PageReport> = (props) => {
    const { brokerReport, brokerId } = usePageContext();

    return <BarReport brokerId={brokerId} brokerReport={brokerReport} {...props} />;
};

export default PageBarReport;

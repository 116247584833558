import React from 'react';
import PageBarReport from '../../components/PageBarReport';

const VerhaltnisKundenPrivatGewerbe = () => {
    return (
        <PageBarReport
            title="Verhältnis Kunden privat/Gewerbe"
            reportCode="customer-distribution-private-business"
            percentage
            roundDigits
        />
    );
};

export default VerhaltnisKundenPrivatGewerbe;

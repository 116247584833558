import React, { useState } from 'react';
import { useMount } from 'react-use';

import { usePageContext } from './BasePageContext';
import Checkbox from '../../form/Checkbox/Checkbox';
import AuthContext from '../../../providers/AuthContext';
import BrokerService from '../../../services/BrokerService';
import SelectBox from '../../SelectBox/SelectBox';

type Props = {
    title: string;
};

const BasePage: React.FC<Props> = ({ title, children }) => {
    const { brokerReport, setBrokerReport, setBrokerId } = usePageContext();
    const [subBrokers, setSubBrokers] = useState<string[]>([]);

    const user = AuthContext.getUser();

    const onChecked = () => {
        setBrokerReport(!brokerReport);
    };

    useMount(() => {
        BrokerService.getChildren().then((subBrokers) => setSubBrokers([user?.id || '', ...subBrokers]));
    });

    return (
        <main>
            <header>
                <h1>{title}</h1>
                <div className="drp-broker-selector">
                    {subBrokers.length > 0 && (
                        <>
                            <SelectBox
                                prefix="Vermittler auswählen: "
                                value={AuthContext.getUser()?.activeBrokerId || user.id}
                                options={subBrokers.map((brokerId) => ({ label: brokerId, value: brokerId }))}
                                onChange={(value) => {
                                    const user = AuthContext.getUser();
                                    user.activeBrokerId = value;
                                    AuthContext.setUser(user);
                                    setBrokerId(value);
                                }}
                            />
                            <Checkbox label="Untervermittler anzeigen" checked={!brokerReport} onChecked={onChecked} />
                        </>
                    )}
                    {process.env.REACT_APP_MOCK_BROKER === 'yes' && (
                        <select
                            value={AuthContext.getUser()?.id}
                            onChange={(e) => {
                                const user = AuthContext.getUser();
                                user.id = e.currentTarget.value;
                                user.activeBrokerId = e.currentTarget.value;
                                AuthContext.setUser(user);
                                window.location.reload();
                            }}
                            style={{
                                background: '#ffffff',
                                border: '1px solid #CBD5E0',
                                borderRadius: '4px',
                                height: '36px',
                                marginBottom: '10px',
                                padding: '4px',
                                color: '#4A5568',
                                fontSize: '14px'
                            }}>
                            <option id="">- SELECT TEST BROKER -</option>
                            <option id="Root">Root</option>
                            <option id="Child1">Child1</option>
                            <option id="Child9">Child9</option>
                            <option id="009L3T">007127</option>
                            <option id="009L3T">009L3T</option>
                            <option id="00PT0W">00PT0W</option>
                            <option id="009994">009994</option>
                            <option id="417216">417216</option>
                            <option id="006420">006420</option>
                            <option id="88CA64">88CA64</option>
                        </select>
                    )}
                </div>
            </header>
            {children}
        </main>
    );
};

export default BasePage;

import React, { useEffect, useState } from 'react';
import { PointTooltipProps, ResponsiveLine, Serie } from '@nivo/line';

import ChartDefaults from '../ChartDefaults';
import { Line2D } from '../../../models/LineChartData';
import NumberFormatter from '../../../utilities/NumberFormatter';
import EmptyData from '../../EmptyData/EmptyData';

type LineChartProps = {
    data: Line2D;
    roundDigits?: boolean;
    valueSuffix?: string;
    percentage?: boolean;
};

const LineChart: React.FC<LineChartProps> = ({ data, roundDigits = false, valueSuffix, percentage }) => {
    const [max, setMax] = useState<number>(100);

    const transformData = (): Serie[] => {
        return data?.data
            ? [{ id: data.name, data: data.data.map((item) => ({ x: item.x, y: percentage ? item.y * 100 : item.y })) }]
            : [];
    };

    useEffect(() => {
        const maxValue = data?.data.reduce((innerMax, point) => Math.max(innerMax, point.y), 0);

        setMax(maxValue * 1.1 * (percentage ? 100 : 1));
    }, [data, percentage]);

    if (!data?.data?.length) {
        return <EmptyData />;
    }

    return (
        <ResponsiveLine
            data={transformData()}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: max, stacked: false }}
            pointSize={8}
            pointBorderWidth={0}
            pointLabelYOffset={-12}
            useMesh={true}
            enableCrosshair={false}
            tooltip={(props) => (
                <Tooltip
                    maxItems={transformData().length}
                    valueSuffix={valueSuffix}
                    roundDigits={roundDigits}
                    {...props}
                />
            )}
            {...ChartDefaults(max, percentage)}
        />
    );
};

const Tooltip: React.FC<PointTooltipProps & { maxItems: number; roundDigits?: boolean; valueSuffix?: string }> = ({
    point,
    maxItems,
    roundDigits,
    valueSuffix
}) => {
    const { id, serieId, data } = point;

    const position = Number(id.replace(serieId + '.', ''));

    return (
        <div
            className={
                'dr-chart-tooltip drm--small ' + (position > Math.ceil(maxItems / 2) ? 'drm--left' : 'drm--right')
            }>
            <span className="drm--text">
                {NumberFormatter.format(data.y as string, roundDigits ? 2 : undefined)}
                {valueSuffix}
            </span>
        </div>
    );
};

export default LineChart;

import React from 'react';
import PageNumberReport from '../../components/PageNumberReport';

const Besicherungsquote = () => {
    return (
        <PageNumberReport title="Besicherungsquote" reportCode="collateralization-quote" valueSuffix="%" roundDigits />
    );
};

export default Besicherungsquote;

import React from 'react';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachVertragsstatus = () => {
    return (
        <PageBarReport
            title="Verteilung nach Vertragsstatus"
            reportCode="distribution-per-contract-status"
            roundDigits
        />
    );
};

export default VerteilungNachVertragsstatus;

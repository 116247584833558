import React from 'react';

import { PageReport } from '../../components/reports/Report';
import { usePageContext } from '../../components/pages/BasePage/BasePageContext';
import LineReport from '../../components/reports/LineReport';

const PageLineReport: React.FC<PageReport> = (props) => {
    const { brokerReport, brokerId } = usePageContext();

    return <LineReport brokerId={brokerId} brokerReport={brokerReport} {...props} />;
};

export default PageLineReport;

import React, { useCallback, useState } from 'react';

import LineChartData from '../../models/LineChartData';
import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';
import LineChart from '../chart/LineChart/LineChart';
import BaseReport from './BaseReport';
import Report from './Report';

const LineReport: React.FC<Report> = ({ reportCode, brokerId, brokerReport, title, percentage, ...props }) => {
    const [data, setData] = useState<LineChartData>([]);

    const getData = useCallback(() => {
        return ReportsService.get(reportCode, brokerId).then(DataTransformer.toLineChartData).then(setData);
    }, [brokerId, reportCode]);

    return (
        <BaseReport title={title} getCallback={getData} type="chart">
            <LineChart
                data={brokerReport ? data[0] : data[1]}
                valueSuffix={percentage ? '%' : ''}
                percentage={percentage}
                {...props}
            />
        </BaseReport>
    );
};

export default LineReport;

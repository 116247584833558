import React, { useCallback, useState } from 'react';

import ReportsService from '../../services/ReportsService';
import DataTransformer from '../../utilities/DataTransformer';
import SortingUtility from '../../utilities/SortingUtility';
import DataTable from '../chart/DataTable/DataTable';
import TableChartData from '../../models/TableChartData';
import BaseReport from './BaseReport';
import Report from './Report';

const DataTableReport: React.FC<Report> = ({ reportCode, brokerId, brokerReport, title }) => {
    const [data, setData] = useState<TableChartData[]>([]);

    const getData = useCallback(() => {
        return ReportsService.get(reportCode, brokerId)
            .then(DataTransformer.toTableChartData)
            .then(SortingUtility.sortTableData)
            .then(setData);
    }, [brokerId, reportCode]);

    return (
        <BaseReport title={title} getCallback={getData} type="table">
            <DataTable data={brokerReport ? data[0] : data[1]} />
        </BaseReport>
    );
};

export default DataTableReport;

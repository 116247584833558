import React from 'react';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachSpartenLV = () => {
    return (
        <PageBarReport
            title="Verteilung nach Sparten LV"
            reportCode="distribution-per-sub-sections-for-life-insurances"
        />
    );
};

export default VerteilungNachSpartenLV;

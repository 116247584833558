import React from 'react';
import PageLineReport from '../../components/PageLineReport';

const MahnquoteVonVertragenImHaftungszeitraum = () => {
    return (
        <PageLineReport
            title="Mahnquote von Verträgen im Haftungszeitraum"
            reportCode="admonition-quote-of-all-contracts-in-the-period-of-liability"
            roundDigits
        />
    );
};

export default MahnquoteVonVertragenImHaftungszeitraum;

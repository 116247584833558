export const Colors = [
    '#485B90',
    '#42A9E0',
    '#047C2C',
    '#00AB91',
    '#42AC01',
    '#D2A801',
    '#D67701',
    '#A4020F',
    '#86005E',
    '#D1D6E3',
    '#D0EAF7',
    '#C0DECA',
    '#BFEAE4',
    '#D0EAC0',
    '#F4E9C0',
    '#F5DDC0',
    '#E8C0C3',
    '#E1BFD7'
];

export const Months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];

import BaseService from './BaseService';
import ReportData from '../models/ReportData';
import { Months } from '../constants';
import AuthContext from '../providers/AuthContext';

type ResponseDatum = {
    month: number;
    year: number;
    report?: {
        broker: any;
        children: any;
    } | null;
};

const transform = (response: ResponseDatum[]): ReportData => {
    return (response || [])
        .filter((a) => a?.report && (a.report.broker || a.report.children))
        .sort((a, b) => {
            if (a.year === b.year) {
                return a.month - b.month;
            }

            return a.year - b.year;
        })
        .map((datum) => ({
            period: Months[datum.month - 1],
            report: { broker: datum.report?.broker || {}, network: datum.report?.children || {} }
        }));
};

const ReportsService = {
    get: (reportType: string, brokerId?: string): Promise<ReportData> => {
        const service = BaseService();
        const user = AuthContext.getUser();

        return service
            .get(`/reports/${reportType}/${brokerId || user.activeBrokerId || user.id}`)
            .then(transform)
            .catch((error: any) => Promise.reject(error.response?.data || error));
    }
};

export default ReportsService;

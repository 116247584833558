import React from 'react';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachGeschlecht = () => {
    return (
        <PageBarReport title="Verteilung nach Geschlecht" reportCode="distribution-per-sex" percentage roundDigits />
    );
};

export default VerteilungNachGeschlecht;

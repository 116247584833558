import React, { useState } from 'react';

import { BaseContextType, BasePageContext } from './BasePageContext';

const BasePageProvider = (props: any) => {
    const [brokerReport, setBrokerReport] = useState<boolean>(true);
    const [brokerId, setBrokerId] = useState<string>('');

    const providerValue: BaseContextType = {
        brokerReport,
        brokerId,
        setBrokerReport,
        setBrokerId
    };

    return <BasePageContext.Provider value={providerValue}>{props.children}</BasePageContext.Provider>;
};

export default BasePageProvider;

import React from 'react';
import PageDataTableReport from '../../components/PageDataTableReport';

const VerteilungDerGesellschaftenSach = () => {
    return (
        <PageDataTableReport
            title="Verteilung der Gesellschaften Komposit"
            reportCode="distribution-of-the-insurance-companies-in-the-property-liabilities"
        />
    );
};

export default VerteilungDerGesellschaftenSach;

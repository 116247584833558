import React from 'react';
import PageBarReport from '../../components/PageBarReport';

const VerteilungNachSpartenKV = () => {
    return (
        <PageBarReport
            title="Verteilung nach Sparten KV"
            reportCode="distribution-per-sub-sections-for-health-insurances"
        />
    );
};

export default VerteilungNachSpartenKV;

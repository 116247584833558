import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

type Props = {
    prefix?: string;
    value?: string;
    options: {
        label: string;
        value: string;
    }[];
    onChange: (value: string) => void;
};

const SelectBox: React.FC<Props> = ({ prefix = '', value, options, onChange }) => {
    const [opened, setOpened] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, () => setOpened(false));

    return (
        <div ref={ref} className={'dr-select-box' + (opened ? ' lgs--opened' : '')}>
            <button onClick={() => setOpened((prevState) => !prevState)}>
                <span>{prefix + options.find((o) => o.value === value)?.label}</span>
                <svg className="drp-icon" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 1L7 7L13 1"
                        stroke="#4A5568"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <ul>
                {options.map((option) => (
                    <li
                        key={option.value}
                        className={option.value === value ? 'lgs--active' : ''}
                        onClick={() => {
                            onChange(option.value);
                            setOpened(false);
                        }}>
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SelectBox;
